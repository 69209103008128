function extractEquipmentSummary(
  serviceNumber,
  equipmentSummary,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal,
  flexPlanName,
  flexiPlanType,
  flexiShareableFlag,
  shareablePlanName,
  shareablePlanName2,
  shareableBonusPlanName1,
  shareableBonusPlanName2,
  nonShareablePlanName1,
  nonShareablePlanName2,
  nonShareableBonusDataPlan1,
  nonShareableBonusDataPlan2,
) {
  // console.log('extractEquipmentSummary started for  service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
  extractEquipmentSummaryRaw(
    equipmentSummary,
    latestBillTotal,
    latestDomesticDataCall,
    latestDomesticDataUsage,
    domesticDataUsage12MonthsCount,
    domesticDataUsage12MonthsTotal,
    domesticDataCall12MonthsCount,
    domesticDataCall12MonthsTotal,
    bill12MonthsCount,
    bill12MonthsTotal,
  )
  // console.log('extractEquipmentSummary calling extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' flexPlanName ' + flexPlanName + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
  //extractPlanEquipmentSummary(serviceNumber, flexPlanName, 'FLEXI', equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
  // console.log('extractEquipmentSummary calling extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' shareablePlanName ' + shareablePlanName + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
  //extractPlanEquipmentSummary(serviceNumber, shareablePlanName, 'SHARABLE', equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
  if (flexiPlanType) {
    extractPlanEquipmentSummary(
      serviceNumber,
      flexPlanName,
      flexiPlanType,
      'FLEXI',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName,
      flexiPlanType,
      'SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName2,
      flexiPlanType,
      'SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareableBonusPlanName1,
      flexiPlanType,
      'SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareableBonusPlanName2,
      flexiPlanType,
      'SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareablePlanName1,
      flexiPlanType,
      'NON SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareablePlanName2,
      flexiPlanType,
      'NON SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareableBonusDataPlan1,
      flexiPlanType,
      'NON SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareableBonusDataPlan2,
      flexiPlanType,
      'NON SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
  } else {
    extractPlanEquipmentSummary(
      serviceNumber,
      flexPlanName,
      flexiPlanType,
      'FLEXI',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName,
      flexiPlanType,
      'SHARABLE',
      equipmentSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
  }
  // console.log('extractEquipmentSummary ENDED for  service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType + ' *******')
}

// function extractAccountEquipmentSummary (accountNo, serviceNumber, equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal, flexPlanName, shareablePlanName) {
//   // console.log('extractAccountEquipmentSummary started for account ' + accountNo + ' service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
//   extractEquipmentSummaryRaw(equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
//   // console.log('extractAccountEquipmentSummary account calling extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' flexPlanName ' + flexPlanName + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
//   extractPlanEquipmentSummary(serviceNumber, flexPlanName, 'FLEXI', equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
//   // console.log('extractAccountEquipmentSummary account calling extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' shareablePlanName ' + shareablePlanName + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
//   extractPlanEquipmentSummary(serviceNumber, shareablePlanName, 'SHARABLE', equipmentSummary, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
//   // console.log('extractAccountEquipmentSummary ENDED for account ' + accountNo + ' service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType + ' *******')
// }

function extractEquipmentSummaryRaw(
  equipmentSummary,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal
) {
  // console.log('extractEquipmentSummaryRaw for equipment ' + equipmentSummary.deviceName + ' type ' + equipmentSummary.deviceType + ' adding ' + bill12MonthsTotal + ' to ' + equipmentSummary.bill12MonthsTotal)
  equipmentSummary.qty = equipmentSummary.qty + 1
  equipmentSummary.billTotal = equipmentSummary.billTotal + latestBillTotal
  if (latestDomesticDataCall > 0) {
    equipmentSummary.domesticDataCallCount = equipmentSummary.domesticDataCallCount + 1
    equipmentSummary.domesticDataCall = equipmentSummary.domesticDataCall + latestDomesticDataCall
  }
  if (latestDomesticDataUsage > 0) {
    equipmentSummary.domesticDataUsageCount = equipmentSummary.domesticDataUsageCount + 1
    equipmentSummary.domesticDataUsage = equipmentSummary.domesticDataUsage + latestDomesticDataUsage
  }
  equipmentSummary.domesticDataUsage12MonthsCount = equipmentSummary.domesticDataUsage12MonthsCount + domesticDataUsage12MonthsCount
  equipmentSummary.domesticDataUsage12MonthsTotal = equipmentSummary.domesticDataUsage12MonthsTotal + domesticDataUsage12MonthsTotal
  equipmentSummary.domesticDataCall12MonthsCount = equipmentSummary.domesticDataCall12MonthsCount + domesticDataCall12MonthsCount
  equipmentSummary.domesticDataCall12MonthsTotal = equipmentSummary.domesticDataCall12MonthsTotal + domesticDataCall12MonthsTotal
  equipmentSummary.bill12MonthsCount = equipmentSummary.bill12MonthsCount + bill12MonthsCount
  equipmentSummary.bill12MonthsTotal = equipmentSummary.bill12MonthsTotal + bill12MonthsTotal
}

/**
 *
 * @param serviceNumber
 * @param planName
 * @param flexiPlanType
 * @param planType
 * @param equipmentSummary
 * @param latestBillTotal
 * @param latestDomesticDataCall
 * @param latestDomesticDataUsage
 * @param domesticDataUsage12MonthsCount
 * @param domesticDataUsage12MonthsTotal
 * @param domesticDataCall12MonthsCount
 * @param domesticDataCall12MonthsTotal
 * @param bill12MonthsCount
 * @param bill12MonthsTotal
 */
function extractPlanEquipmentSummary(
  serviceNumber,
  planName,
  flexiPlanType,
  planType,
  equipmentSummary,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal
) {
  if (planName) {
    // console.log('extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' planName ' + planName + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
    let sharePlan = equipmentSummary.planMap.get(planName)
    if (!sharePlan) {
      // console.log('extractPlanEquipmentSummary calling createEquipmentUnit for service number ' + serviceNumber.sn + ' planName ' + planName)
      const planSummary = createEquipmentUnit(
        flexiPlanType,
        serviceNumber,
        latestBillTotal,
        latestDomesticDataCall,
        latestDomesticDataUsage,
        domesticDataUsage12MonthsCount,
        domesticDataUsage12MonthsTotal,
        domesticDataCall12MonthsCount,
        domesticDataCall12MonthsTotal,
        bill12MonthsCount,
        bill12MonthsTotal
      )
      planSummary.planName = planName
      planSummary.planType = planType
      equipmentSummary.planMap.set(planName, planSummary)
    } else {
      // console.log('extractPlanEquipmentSummary plan ' + planName + ' exists calling extractEquipmentSummaryRaw for service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType, sharePlan)
      // extractEquipmentPlanSummaryRaw(sharePlan, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal)
      extractEquipmentSummaryRaw(
        sharePlan,
        latestBillTotal,
        latestDomesticDataCall,
        latestDomesticDataUsage,
        domesticDataUsage12MonthsCount,
        domesticDataUsage12MonthsTotal,
        domesticDataCall12MonthsCount,
        domesticDataCall12MonthsTotal,
        bill12MonthsCount,
        bill12MonthsTotal
      )
    }
    // console.log('extractPlanEquipmentSummary for service number ' + serviceNumber.sn + ' planName ' + planName + ' ended ****')
  }
}

function extractEquipmentPlanSummaryRaw(
  equipmentSummary,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal
) {
  // console.log('extractEquipmentPlanSummaryRaw for equipment ' + equipmentSummary.deviceName + ' type ' + equipmentSummary.deviceType + ' adding ' + bill12MonthsTotal + ' to ' + equipmentSummary.bill12MonthsTotal)
  equipmentSummary.qty = equipmentSummary.qty + 1
  equipmentSummary.billTotal = equipmentSummary.billTotal + latestBillTotal
  if (latestDomesticDataCall > 0) {
    equipmentSummary.domesticDataCallCount = equipmentSummary.domesticDataCallCount + 1
    equipmentSummary.domesticDataCall = equipmentSummary.domesticDataCall + latestDomesticDataCall
  }
  if (latestDomesticDataUsage > 0) {
    equipmentSummary.domesticDataUsageCount = equipmentSummary.domesticDataUsageCount + 1
    equipmentSummary.domesticDataUsage = equipmentSummary.domesticDataUsage + latestDomesticDataUsage
  }
  equipmentSummary.domesticDataUsage12MonthsCount = equipmentSummary.domesticDataUsage12MonthsCount + domesticDataUsage12MonthsCount
  equipmentSummary.domesticDataUsage12MonthsTotal = equipmentSummary.domesticDataUsage12MonthsTotal + domesticDataUsage12MonthsTotal
  equipmentSummary.domesticDataCall12MonthsCount = equipmentSummary.domesticDataCall12MonthsCount + domesticDataCall12MonthsCount
  equipmentSummary.domesticDataCall12MonthsTotal = equipmentSummary.domesticDataCall12MonthsTotal + domesticDataCall12MonthsTotal
  equipmentSummary.bill12MonthsCount = equipmentSummary.bill12MonthsCount + bill12MonthsCount
  equipmentSummary.bill12MonthsTotal = equipmentSummary.bill12MonthsTotal + bill12MonthsTotal
}

function createEquipmentUnit(
  flexiPlanType,
  serviceNumber,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal,
  mroContractDeviceName,
) {
  // console.log('createEquipmentUnit for service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType + ' bill12MonthsTotal ' + bill12MonthsTotal)
  const accountSummary = {
    deviceType: serviceNumber.deviceType,
    make: serviceNumber.make,
    deviceName: serviceNumber.deviceName,
    model: serviceNumber.model,
    qty: 1,
    billTotal: latestBillTotal,
    domesticDataCall: latestDomesticDataCall,
    domesticDataUsage: latestDomesticDataUsage,
    domesticDataCallCount: 1, // TODO only set to 1 is data call is > 0
    domesticDataUsageCount: 1, // TODO only set to 1 is data usage is > 0
    domesticDataUsage12MonthsCount: domesticDataUsage12MonthsCount,
    domesticDataUsage12MonthsTotal: domesticDataUsage12MonthsTotal,
    domesticDataCall12MonthsCount: domesticDataCall12MonthsCount,
    domesticDataCall12MonthsTotal: domesticDataCall12MonthsTotal,
    bill12MonthsCount: bill12MonthsCount,
    bill12MonthsTotal: bill12MonthsTotal,
    flexiPlanType: flexiPlanType,
    mroContractDeviceName: mroContractDeviceName,
    planMap: new Map()
    // shareablePlanMap: new Map(),
  }
  return accountSummary
}

/**
 *
 * @param serviceNumber
 * @param latestBillTotal
 * @param latestDomesticDataCall
 * @param latestDomesticDataUsage
 * @param domesticDataUsage12MonthsCount
 * @param domesticDataUsage12MonthsTotal
 * @param domesticDataCall12MonthsCount
 * @param domesticDataCall12MonthsTotal
 * @param bill12MonthsCount
 * @param bill12MonthsTotal
 * @param flexPlanName
 * @param flexiPlanType
 * @param flexiShareableFlag
 * @param shareablePlanName
 * @param shareablePlanName2
 * @param shareableBonusPlanName1
 * @param shareableBonusPlanName2
 * @param nonShareablePlanName1
 * @param nonShareablePlanName2
 * @param nonShareableBonusDataPlan1
 * @param nonShareableBonusDataPlan2
 * @param mroContractDeviceName
 * @returns {{deviceType: (string|null), domesticDataCall12MonthsTotal: *, domesticDataCallCount: number, bill12MonthsTotal: *, domesticDataUsage: *, domesticDataCall12MonthsCount: *, bill12MonthsCount: *, deviceName: *, domesticDataUsageCount: number, billTotal: *, domesticDataUsage12MonthsTotal: *, qty: number, domesticDataUsage12MonthsCount: *, make: (() => void) | null | AsyncParallelHook<webpack.compilation.Compilation>, domesticDataCall: *}}
 */
function createEquipmentSummary(
  serviceNumber,
  latestBillTotal,
  latestDomesticDataCall,
  latestDomesticDataUsage,
  domesticDataUsage12MonthsCount,
  domesticDataUsage12MonthsTotal,
  domesticDataCall12MonthsCount,
  domesticDataCall12MonthsTotal,
  bill12MonthsCount,
  bill12MonthsTotal,
  flexPlanName,
  flexiPlanType,
  flexiShareableFlag,
  shareablePlanName,
  shareablePlanName2,
  shareableBonusPlanName1,
  shareableBonusPlanName2,
  nonShareablePlanName1,
  nonShareablePlanName2,
  nonShareableBonusDataPlan1,
  nonShareableBonusDataPlan2,
  mroContractDeviceName,
) {
  // console.log('createEquipmentSummary calling createEquipmentUnit for service number ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
  const accountSummary = createEquipmentUnit(
    flexiPlanType,
    serviceNumber,
    latestBillTotal,
    latestDomesticDataCall,
    latestDomesticDataUsage,
    domesticDataUsage12MonthsCount,
    domesticDataUsage12MonthsTotal,
    domesticDataCall12MonthsCount,
    domesticDataCall12MonthsTotal,
    bill12MonthsCount,
    bill12MonthsTotal,
    mroContractDeviceName,
  )
  // do the same thing for plans..
  if (flexiPlanType) {
    extractPlanEquipmentSummary(
      serviceNumber,
      flexPlanName,
      flexiPlanType,
      'FLEXI',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName,
      flexiPlanType,
      'SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal,
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName2,
      flexiPlanType,
      'SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareableBonusPlanName1,
      flexiPlanType,
      'SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareableBonusPlanName2,
      flexiPlanType,
      'SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareablePlanName1,
      flexiPlanType,
      'NON SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareablePlanName2,
      flexiPlanType,
      'NON SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareableBonusDataPlan1,
      flexiPlanType,
      'NON SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      nonShareableBonusDataPlan2,
      flexiPlanType,
      'NON SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
  } else {
    extractPlanEquipmentSummary(
      serviceNumber,
      flexPlanName,
      flexiPlanType,
      'FLEXI',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
    extractPlanEquipmentSummary(
      serviceNumber,
      shareablePlanName,
      flexiPlanType,
      'SHARABLE',
      accountSummary,
      latestBillTotal,
      latestDomesticDataCall,
      latestDomesticDataUsage,
      domesticDataUsage12MonthsCount,
      domesticDataUsage12MonthsTotal,
      domesticDataCall12MonthsCount,
      domesticDataCall12MonthsTotal,
      bill12MonthsCount,
      bill12MonthsTotal
    )
  }
  return accountSummary

  //    acctNumbers: [],
  //    acctSummaryMap: new Map(),
}

/**
 * @param serviceNumber
 * @param mroContractDeviceName
 * @param mroContractStartDate
 * @param mroContractEndDate
 * @param mroMonthlyCommitment
 * @param mroContractETC
 * @returns {{deviceType, mroContractDeviceName, mroContractStartDate, mroMonthlyCommitment, model, sn, make, deviceName, mroContractEndDate, mroContractETC}}
 */
function createDeviceInContract (
  serviceNumber,
  mroContractDeviceName,
  mroContractStartDate,
  mroContractEndDate,
  mroMonthlyCommitment,
  mroContractETC,
) {
  return {
    sn: serviceNumber.sn,
    deviceType: serviceNumber.deviceType,
    make: serviceNumber.make,
    deviceName: serviceNumber.deviceName,
    model: serviceNumber.model,
    mroContractDeviceName: mroContractDeviceName,
    mroContractStartDate: mroContractStartDate,
    mroContractEndDate: mroContractEndDate,
    mroMonthlyCommitment: mroMonthlyCommitment,
    mroContractETC: mroContractETC,
  }
}

export { extractEquipmentSummary, createEquipmentSummary, createDeviceInContract }
