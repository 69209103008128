/**
 * preprocess the biltz file..
 *
 */
import store from '@/store'
import { isEmpty } from 'lodash'
import moment from 'moment'
import Utils from '@/api/util'
import { extractEquipmentSummary, createEquipmentSummary, createDeviceInContract } from '@/api/PreprocessorUtil'
import {
  bulkInsertEquipmentSummary,
  bulkInsertSn,
  bulkInsertBuyback,
  deleteDatabase,
  deleteBuybackDatabase,
  insertCustomerInfo,
  bulkInsertSnMonthUsage,
  getBuybackDb,
  bulkInsertDeviceInContract
} from './PouchDao'

// const DOMESTIC_DATA_CALLS_PATTERN =  "^[A-Z][a-z](2)\sd{4} Domestic Data Calls"; //eg Aug 2018 Domestic Data Calls
// const T1 = new RegExp("^[A-Z][a-z]{2}\sd{4}");

// const dtPattern = /^(([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])(-)(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(-)((19|20)\d{2}))$/i;
// const mtPattern = /^((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((19|20)\d{2})\s([A-Z ]+))$/;
// const mtPattern = /^((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((19|20)\d{2})\s([A-Z ]+)\s(CALLS|MINUTES OF USE|USAGE|EXCESS USAGE|SMS)$)/;
const mtPattern =
  /^((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((20)\d{2})\s([A-Z ]+)\s(CALLS|MINUTES OF USE|USAGE \(MB\)|EXCESS USAGE \(\$\)|SMS)$)/ // USAGE (MB) or EXCESS USAGE ($)

const billPattern = /^((BILL TOTAL)\s(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((20)\d{2})$)/ // BILL TOTAL AUG 2017

const groupPattern = /(([A-Z\-]+)\-((20)\d{2})\-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)$)/

const monthHash = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12'
}

const DOMESTIC_DATA = 'DOMESTIC DATA'
const DOMESTIC_VOICE = 'DOMESTIC VOICE'
const OVERSEAS_DATA = 'OVERSEAS DATA'

/**
 * delete all existing sn and snUsage
 *
 * @param rows
 * @returns {Promise<{snCount: *, billingName: *, categories: *, customerName: *}>}
 */
async function loadData(fileName, rows, partner) {
  console.log('loadData started for file ' + fileName)
  await store.dispatch('app/setLoadedDataFlag', false)
  const columnYearMonths = await showMe(rows)
  console.log('loadData showMe ended next calling buildServiceUsages...')
  const [
    serviceNumbers,
    snMonthCategoryUsageList,
    equipmentSummaryList,
    customerName,
    billingName,
    businessUnit,
    bill12MonthsGrandTotal,
    biltzFileVersion,
    deviceInContractList,
  ] = await buildServiceUsages(rows, columnYearMonths)
  let headerColumns = columnYearMonths.headerColumns
  const accountNo = rows[1][headerColumns.get('Account Number'.toUpperCase())]
  const ucidn = rows[1][headerColumns.get('UCIDN'.toUpperCase())]
  // try concurrent insert...
  let processing = deleteDatabase()
    .then((res) => {
      return bulkInsertSn(ucidn, accountNo, serviceNumbers)
    })
    .then((res) => {
      return bulkInsertEquipmentSummary(ucidn, accountNo, equipmentSummaryList)
      }).then((res) => {
      return bulkInsertDeviceInContract(deviceInContractList)
    })
    .then((res) => {
      return bulkInsertSnMonthUsage(ucidn, accountNo, snMonthCategoryUsageList)
    })
    .then((res) => {
      console.log(
        'loadData bulk inserts ended - now processing customer info resolving the earliest and latest month',
        columnYearMonths.billTotalMonth
      )
      const sortedBillTotalMonths = columnYearMonths.billTotalMonth
      // sort the list in desc yearMonth property so the latest appear first...
      sortedBillTotalMonths.sort((a, b) => a.yearMonth - b.yearMonth)
      // console.log('loadData bulk inserts ended - now processing customer info sorted billTotalMonths', billTotalMonths)
      let earliest = sortedBillTotalMonths[0].yearMonth
      let latest = sortedBillTotalMonths[columnYearMonths.billTotalMonth.length - 1].yearMonth
      console.log('loadData yearMonth earliest ' + earliest + ' latest ' + latest)
      let yearMonthSelectList = []
      columnYearMonths.billTotalMonth.forEach((billMonth) => {
        yearMonthSelectList.push({
          yearMonth: billMonth.yearMonth,
          label: billMonth.monthLabel + ' ' + billMonth.year
        })
      })
      const accountsWithSnCount = serviceNumbers
        .map((sn) => {
          return { acct: sn.acct, count: 1 }
        })
        .reduce(
          (accArray, snAcct) => {
            const found = accArray.find((a) => a.acct === snAcct.acct)
            if (found) {
              found.count = found.count + 1 // if found, that means data property exists, so just push new element to found.data.
            } else {
              const value = { acct: snAcct.acct, count: snAcct.count, sortOrder: snAcct.acct } // the element in data property
              accArray.push(value) // not found, so need to add data property
            }
            return accArray
          },
          [{ acct: 'ALL', count: serviceNumbers.length, sortOrder: 0 }]
        )

      // console.log('ucidn is ' + ucidn)
      return insertCustomerInfo(
        businessUnit,
        customerName,
        billingName,
        ucidn,
        bill12MonthsGrandTotal,
        fileName,
        serviceNumbers.length,
        columnYearMonths.categories,
        earliest,
        yearMonthSelectList,
        latest,
        [...accountsWithSnCount].sort((a, b) => {
          a.acct - b.acct
        }),
        partner,
        biltzFileVersion
        // [...flexiPlanList].sort((a, b) => { a.total - b.total }),
        // [...shareablePlanList].sort((a, b) => { a.total - b.total }),
      ).then((result) => {
        store.dispatch('app/setProgress', false)
        store.dispatch('dashboard/refreshRequired', true)
        store.dispatch('app/loadData', true)
        return Promise.resolve({
          customerName: customerName,
          billingName: billingName,
          snCount: serviceNumbers.length,
          categories: columnYearMonths.categories
        })
      })
    })
  // console.log('processData processing issued')
  return processing
}

async function loadBuybackData(fileName, rows) {
  console.log('loadBuybackData started for file ' + fileName)
  const buybackPrice = await buildBuybackPrice(rows)
  return deleteBuybackDatabase()
    .then(() => {
      return bulkInsertBuyback(buybackPrice)
    })
    .then(() => {
      store.dispatch('app/setProgress', false)
      return true
    })
    .catch((e) => {
      throw new Error(e)
    })
}

async function resetGlobalAccountFilter() {
  return store.dispatch('app/resetGlobalFilterToAll')
}

/**
 *  this scans the header (first row) for month year pattern for later detection of data usages and bill total
 * @param rows
 * @returns {Promise<{headerColumns: *, callMonth: *, billTotalMonth: *}>}
 */
async function showMe(rows) {
  console.log('showMe started analysing ' + rows.length + ' rows of service numbers')
  let callMonth = []
  let billTotalMonth = []
  const headerColumns = new Map()
  const headerRow = rows[0]
  let columnNumber = 0
  const iMax = headerRow.length
  let latestYearMonth = 201801
  for (; columnNumber < iMax; columnNumber++) {
    let matchResult = headerRow[columnNumber].toUpperCase().match(mtPattern)
    headerColumns.set(headerRow[columnNumber].toUpperCase(), columnNumber)
    if (matchResult) {
      // console.log("column header " + headerRow[i] + " match " + matchResult);
      let monthLabel = matchResult[2]
      let year = matchResult[3]
      let category = matchResult[5] // eg DOMESTIC DATA
      let categoryGroup = matchResult[5].split(' ').join('-') // eg DOMESTIC-DATA
      let categoryType = matchResult[6] // eg CALLS | USAGES | MINUTES OF USER USAGE |
      // let colIndex = columnNumber // index starts at 0
      let id = matchResult[1].split(' ').join('-') + '-' + columnNumber // eg JUL-2018-DOMESTIC-SMS-1
      callMonth.push({
        _id: id,
        // colIndex: colIndex,
        colIndex: columnNumber,
        year: year,
        monthLabel: monthLabel,
        category: category,
        categoryGroup: categoryGroup,
        categoryType: categoryType
      })
    }
    // process the bill total
    let matchBillTotalResult = headerRow[columnNumber].toUpperCase().match(billPattern)
    if (matchBillTotalResult) {
      let monthLabel = matchBillTotalResult[3] // eg NOV
      let year = matchBillTotalResult[4] // eg 2018
      let category = matchBillTotalResult[2] // eg BILL TOTAL
      let categoryGroup = matchBillTotalResult[2].split(' ').join('-') // eg BILL-TOTAL
      let colIndex = columnNumber // index starts at 0
      // let yearMonth = parseInt(year + monthHash[monthLabel], 10) // eg 201807
      let yearMonth = Utils.convertToYearMonth(year, monthLabel)
      let billrowid = matchBillTotalResult[1].split(' ').join('-') + '-' + columnNumber // eg BILL-TOTAL-NOV-2017-1
      billTotalMonth.push({
        _id: billrowid,
        colIndex: colIndex,
        year: year,
        monthLabel: monthLabel,
        category: category,
        categoryGroup: categoryGroup,
        yearMonth: yearMonth
      })
    }
  }

  billTotalMonth
    .filter((billTotal) => billTotal.category === 'BILL TOTAL')
    .forEach((billTotal) => {
      const yearMonth = billTotal.yearMonth
      if (yearMonth > latestYearMonth) {
        latestYearMonth = yearMonth
      }
    })

  // result the callMonth to get the categories
  let categories = callMonth
    .map((cm) => cm.category)
    .reduce((acc, it) => {
      if (!acc.includes(it)) {
        acc.push(it)
      }
      return acc // return the accululator
    }, [])
  // console.log(categories)
  // save it...
  // await store.dispatch('report/saveCallMonthAndTotal', { callMonth: callMonth, billTotalMonth: billTotalMonth })
  console.log('showMe ended injested ' + callMonth.length + ' call month and ' + billTotalMonth.length + ' bill month')
  return Promise.resolve({
    callMonth: callMonth,
    headerColumns: headerColumns,
    billTotalMonth: billTotalMonth,
    categories: categories,
    latestYearMonth: latestYearMonth
  })
}

/**
 * create object with serviceNumber, accountNumber, category, call, minutes, usage, excess usage...
 * create object with serviceNumber, accountNumber, category, count, duration minutes, usage - mb, excess usage - mb, sms
 * create object with billtotal
 * @param rows
 * @param callMonth
 * @returns {Promise<[]>}
 */
async function buildServiceUsages(rows, columns) {
  // let serviceUsages = []
  let serviceNumbers = []
  let globalAccounts = new Map()
  let snMonthCategoryUsageList = []
  let callMonth = columns.callMonth
  let billTotalMonth = columns.billTotalMonth
  let headerColumns = columns.headerColumns
  let rowNumber = 1 // row 0 is the header hence setting i to 1
  let biltzFileVersion = '1.0'
  if (headerColumns.get('Bar Roaming'.toUpperCase())) {
    biltzFileVersion = '2.0'
  }

  const iMax = rows.length // row 0 is the header hence setting i to 1
  const customerName = rows[rowNumber][headerColumns.get('Customer Name'.toUpperCase())]
  const businessUnit = rows[rowNumber][headerColumns.get('Business Unit'.toUpperCase())]
  const billingName = rows[rowNumber][headerColumns.get('Billing Name'.toUpperCase())]
  const latestYearMonth = columns.latestYearMonth

  //Calculate the latest three month
  const latestmonth = moment(latestYearMonth + '01', 'YYYYMMDD').toDate()
  const latestmonth1 = moment(latestmonth).format('MMM YYYY')
  const latestmonth2 = moment(latestmonth).subtract(1, 'months').format('MMM YYYY')
  const latestmonth3 = moment(latestmonth).subtract(2, 'months').format('MMM YYYY')

  let equipmentSummaryMap = new Map()
  const deviceInContractMap = new Map()
  //let latestYearMonth = 201801 // current usage month of this file
  let bill12MonthsGrandTotal = 0.0
  const groupedCategoryByYearMonth = groupBy(callMonth, (callCat) => callCat.categoryGroup + '-' + callCat.year + '-' + callCat.monthLabel)

  // ------------------------------------------------------------------
  // ********************** BIG LOOP STARTS HERE **********************
  // ------------------------------------------------------------------
  for (; rowNumber < iMax; rowNumber++) {
    // go over each row ie service number
    let serviceLine = rows[rowNumber]
    const accountNo = serviceLine[headerColumns.get('Account Number'.toUpperCase())]
    globalAccounts.set(accountNo, accountNo)
    const sn = serviceLine[headerColumns.get('Mobile Number'.toUpperCase())]

    if (sn && sn.trim() != '') {
      //Only proceed if mobile number is not empty
      const userName = serviceLine[headerColumns.get('User Name'.toUpperCase())]
      // for computing equipment summary
      let latestActive = false
      let latestBillTotal = 0.0
      let latestDomesticDataCall = 0
      let latestDomesticDataUsage = 0
      let domesticDataCall12MonthsTotal = 0
      let domesticDataUsage12MonthsTotal = 0
      let bill12MonthsTotal = 0.0
      let domesticDataCall12MonthsCount = 0 // count number of month where the data call is > 0
      let domesticDataUsage12MonthsCount = 0
      let bill12MonthsCount = 0
      let avg3MonthsDomesticDataUsage = serviceLine[headerColumns.get('Average 3 Month Domestic Data (MB)'.toUpperCase())]
      let avg3MonthsDomesticVoiceDuration = serviceLine[headerColumns.get('Average 3 Month Domestic Voice Minutes of Use'.toUpperCase())]
      let avg3MonthsBill = serviceLine[headerColumns.get('Average 3 Month Bill'.toUpperCase())]
      let shareablePlanName = ''
      let nonShareablePlanName = ''
      let shareablePlanFee
      let shareableDataPlan
      let totalDataAllowance
      let bonusDataAllowance = 0

      const flexPlanName = serviceLine[headerColumns.get('Flexiplan Name'.toUpperCase())] // can be empty
      const flexPlanFee = serviceLine[headerColumns.get('Flexiplan Monthly Fee'.toUpperCase())] // can be empty
      const flexiPlanType = serviceLine[headerColumns.get('Flexiplan Type'.toUpperCase())] // Only Ver 2.0
      const flexiShareableFlag = serviceLine[headerColumns.get('Flexi Shareable Flag'.toUpperCase())] // Only Ver 2.0

      if (biltzFileVersion == '1.0') {
        shareablePlanName = serviceLine[headerColumns.get('Shareable Data Plan Description'.toUpperCase())] // can be empty
        shareablePlanFee = serviceLine[headerColumns.get('Sharable Data Plan Monthly Commitment'.toUpperCase())] // can be empty
        shareableDataPlan = serviceLine[headerColumns.get('Shareable Data Plan'.toUpperCase())]

        totalDataAllowance = serviceLine[headerColumns.get('Total Data Allowance (MB) Excl Bonus'.toUpperCase())] // can be empty
        bonusDataAllowance = serviceLine[headerColumns.get('Bonus Data Allowance (MB)'.toUpperCase())] // can be empty
      } else {
        shareablePlanName = serviceLine[headerColumns.get('Shareable Data Plan 1 Description'.toUpperCase())] // can be empty
        shareablePlanFee = serviceLine[headerColumns.get('Shareable Data Plan 1 Monthly Commitment'.toUpperCase())] // can be empty
        shareableDataPlan = serviceLine[headerColumns.get('Shareable Data Plan 1'.toUpperCase())]

        totalDataAllowance = serviceLine[headerColumns.get('Total Data Allowance (MB)'.toUpperCase())]
      }
      const shareableDataPlan2 = serviceLine[headerColumns.get('Shareable Data Plan 2'.toUpperCase())] // Only Ver 2.0
      const shareablePlanName2 = serviceLine[headerColumns.get('Shareable Data Plan 2 Description'.toUpperCase())] // Only Ver 2.0
      const shareablePlanFee2 = serviceLine[headerColumns.get('Shareable Data Plan 2 Monthly Commitment'.toUpperCase())] // Only Ver 2.0
      const shareableBonusDataPlan1 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 1'.toUpperCase())]
      const shareableBonusPlanName1 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 1 Description'.toUpperCase())]
      const shareableBonusPlanFee1 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 1 Monthly Commitment'.toUpperCase())]
      const shareableBonusDataPlan2 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 2'.toUpperCase())]
      const shareableBonusPlanName2 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 2 Description'.toUpperCase())]
      const shareableBonusPlanFee2 = serviceLine[headerColumns.get('Shareable Bonus Data Plan 2 Monthly Commitment'.toUpperCase())]
      const nonShareableDataPlan1 = serviceLine[headerColumns.get('Non Shareable Data Plan 1'.toUpperCase())]
      const nonShareablePlanName1 = serviceLine[headerColumns.get('Non Shareable Data Plan 1 Description'.toUpperCase())]
      const nonShareablePlanFee1 = serviceLine[headerColumns.get('Non Shareable Data Plan 1 Monthly Commitment'.toUpperCase())]
      const nonShareableDataPlan2 = serviceLine[headerColumns.get('Non Shareable Data Plan 2'.toUpperCase())]
      const nonShareablePlanName2 = serviceLine[headerColumns.get('Non Shareable Data Plan 2 Description'.toUpperCase())]
      const nonShareablePlanFee2 = serviceLine[headerColumns.get('Non Shareable Data Plan 2 Monthly Commitment'.toUpperCase())]
      const nonShareableBonusDataPlan1 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 1'.toUpperCase())]
      const nonShareableBonusPlanName1 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 1 Description'.toUpperCase())]
      const nonShareableBonusPlanFee1 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 1 Monthly Commitment'.toUpperCase())]
      const nonShareableBonusDataPlan2 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 2'.toUpperCase())]
      const nonShareableBonusPlanName2 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 2 Description'.toUpperCase())]
      const nonShareableBonusPlanFee2 = serviceLine[headerColumns.get('Non Shareable Bonus Data Plan 2 Monthly Commitment'.toUpperCase())]
      if (nonShareablePlanName1 || nonShareablePlanName2 || nonShareableBonusPlanName1 || nonShareableBonusPlanName2) {
        nonShareablePlanName = nonShareablePlanName1
      }
      const mroContractDeviceName = serviceLine[headerColumns.get('MRO Contract Device name'.toUpperCase())]
      const mroContractStartDate = serviceLine[headerColumns.get('MRO Contract Start Date'.toUpperCase())]
      const mroContractEndDate = serviceLine[headerColumns.get('MRO Contract End Date'.toUpperCase())]
      const mroMonthlyCommitment = serviceLine[headerColumns.get('MRO Monthly Commitment'.toUpperCase())]
      const mroContractETC = serviceLine[headerColumns.get('MRO Contract ETC'.toUpperCase())]

      // Calculate the last tree month of total usage
      const domesticDataValue1 = serviceLine[headerColumns.get((latestmonth1 + ' ' + DOMESTIC_DATA + ' Calls').toUpperCase())]
      const domesticDataValue2 = serviceLine[headerColumns.get((latestmonth2 + ' ' + DOMESTIC_DATA + ' Calls').toUpperCase())]
      const domesticDataValue3 = serviceLine[headerColumns.get((latestmonth3 + ' ' + DOMESTIC_DATA + ' Calls').toUpperCase())]
      const overseasDataValue1 = serviceLine[headerColumns.get((latestmonth1 + ' ' + OVERSEAS_DATA + ' Calls').toUpperCase())]
      const overseasDataValue2 = serviceLine[headerColumns.get((latestmonth2 + ' ' + OVERSEAS_DATA + ' Calls').toUpperCase())]
      const overseasDataValue3 = serviceLine[headerColumns.get((latestmonth3 + ' ' + OVERSEAS_DATA + ' Calls').toUpperCase())]
      const domesticVoiceValue1 = serviceLine[headerColumns.get((latestmonth1 + ' ' + DOMESTIC_VOICE + ' Calls').toUpperCase())]
      const domesticVoiceValue2 = serviceLine[headerColumns.get((latestmonth2 + ' ' + DOMESTIC_VOICE + ' Calls').toUpperCase())]
      const domesticVoiceValue3 = serviceLine[headerColumns.get((latestmonth3 + ' ' + DOMESTIC_VOICE + ' Calls').toUpperCase())]
      const domesticSMSValue1 = serviceLine[headerColumns.get((latestmonth1 + ' Domestic SMS').toUpperCase())]
      const domesticSMSValue2 = serviceLine[headerColumns.get((latestmonth2 + ' Domestic SMS').toUpperCase())]
      const domesticSMSValue3 = serviceLine[headerColumns.get((latestmonth3 + ' Domestic SMS').toUpperCase())]
      const sumLastThreeMonthUsage =
        domesticDataValue1 +
        domesticDataValue2 +
        domesticDataValue3 +
        overseasDataValue1 +
        overseasDataValue2 +
        overseasDataValue3 +
        domesticVoiceValue1 +
        domesticVoiceValue2 +
        domesticVoiceValue3 +
        domesticSMSValue1 +
        domesticSMSValue2 +
        domesticSMSValue3

      const serviceStartDate = serviceLine[headerColumns.get('Service Start Date'.toUpperCase())]
      const noNetworkActivityInLast3Months = serviceLine[headerColumns.get('No Network Activity In Last 3 Months'.toUpperCase())]
      const noNetworkActivityInLast6Months = serviceLine[headerColumns.get('No Network Activity In Last 6 Months'.toUpperCase())]
      const onlyDiversionUsageInLast3Months = serviceLine[headerColumns.get('Only Diversion Usage In Last 3 Months'.toUpperCase())]
      const onlyDiversionUsageInLast6Months = serviceLine[headerColumns.get('Only Diversion Usage In Last 6 Months'.toUpperCase())]
      const lastUsedNetworkDate = serviceLine[headerColumns.get('Last Used Network Date'.toUpperCase())]
      const last3MonthsMostUsedSite = serviceLine[headerColumns.get('Last 3 Months Most Used Site'.toUpperCase())]
      const last3MonthsMostUsedPostcode = serviceLine[headerColumns.get('Last 3 Months Most Used Postcode'.toUpperCase())]

      //const latestMonthDomesticDataCalls = serviceLine[headerColumns.get(' Domestic Data Calls'.toUpperCase())]

      // customerName = serviceLine[headerColumns.get('Customer Name'.toUpperCase())]
      // go build the bill total
      let snMonthlyBill = {
        _id: 'BILL-TOTAL-' + sn,
        sn: sn,
        userName: userName,
        dataType: 'BILL-TOTAL',
        acct: accountNo,
        avg3Months: avg3MonthsBill
      }
      billTotalMonth.forEach((billMonth) => {
        // iterate through the bill month columns
        let colIndex = billMonth.colIndex
        let monthTotal = serviceLine[colIndex]
        let categoryYear = billMonth.year
        let monthLabel = billMonth.monthLabel
        let yearMonth = Utils.convertToYearMonth(categoryYear, monthLabel) // eg 201808
        snMonthlyBill[yearMonth] = monthTotal
        if (monthTotal > 0) {
          bill12MonthsCount = bill12MonthsCount + 1
          // console.log('bill12MonthsCount updated ' + bill12MonthsCount + ' for SN ' + sn + ' yearMonth ' + yearMonth)
          bill12MonthsTotal = bill12MonthsTotal + monthTotal
        }
        // if (yearMonth > latestYearMonth) { // set the latest month
        //   latestYearMonth = yearMonth
        // }
        if (yearMonth === latestYearMonth) {
          latestBillTotal = monthTotal
        }
      })
      //
      // go build the no usage bill total
      let snMonthlyNoUsageBill = {
        _id: 'NOUSAGE-TOTAL-' + sn,
        sn: sn,
        userName: userName,
        dataType: 'NOUSAGE-TOTAL',
        acct: accountNo,
        serviceStartDate: serviceStartDate,
        noNetworkActivityInLast3Months: noNetworkActivityInLast3Months,
        noNetworkActivityInLast6Months: noNetworkActivityInLast6Months,
        onlyDiversionUsageInLast3Months: onlyDiversionUsageInLast3Months,
        onlyDiversionUsageInLast6Months: onlyDiversionUsageInLast6Months,
        lastUsedNetworkDate: lastUsedNetworkDate,
        last3MonthsMostUsedSite: last3MonthsMostUsedSite,
        last3MonthsMostUsedPostcode: last3MonthsMostUsedPostcode
      }
      billTotalMonth.forEach((billMonth) => {
        // iterate through the bill month columns
        let colIndex = billMonth.colIndex
        let monthTotal = serviceLine[colIndex]
        let categoryYear = billMonth.year
        let monthLabel = billMonth.monthLabel
        let yearMonth = Utils.convertToYearMonth(categoryYear, monthLabel) // eg 201808
        snMonthlyNoUsageBill[yearMonth] = monthTotal
        if (monthTotal > 0) {
          bill12MonthsCount = bill12MonthsCount + 1
          bill12MonthsTotal = bill12MonthsTotal + monthTotal
        }
        // if (yearMonth > latestYearMonth) { // set the latest month
        //   latestYearMonth = yearMonth
        // }
        if (yearMonth === latestYearMonth) {
          latestBillTotal = monthTotal
        }
      })

      //
      if (snMonthlyBill.avg3Months == null) {
        // need to manually calculate avg 3 months bill
        // console.warn(snMonthlyBill.sn + ' avg3months is null now manual calc')
        const month = moment(latestYearMonth + '01', 'YYYYMMDD')
        const month1 = month.subtract(1, 'months')
        const month2 = month.subtract(2, 'months')
        const billMonth0 = snMonthlyBill[latestYearMonth]
        const billMonth1 = snMonthlyBill[month1.format('YYYYMM')]
        const billMonth2 = snMonthlyBill[month2.format('YYYYMM')]
        snMonthlyBill.avg3Months = (billMonth0 + billMonth1 + billMonth2) / 3
      }
      bill12MonthsGrandTotal += bill12MonthsTotal
      snMonthCategoryUsageList.push(snMonthlyBill)

      //Add NoUsage row if the last three month is zero
      if (sumLastThreeMonthUsage == 0) {
        snMonthCategoryUsageList.push(snMonthlyNoUsageBill)
      }
      // need to deflate create temp structure
      // create Map<key,List> where the key is the category name and the list is a list of service month usage object
      // the service number usage should contain service number and month
      // {sn : 12312321 yearMonthUsage: to be map of key = yearMonth value
      let callCategoryUsageMap = new Map()
      // go build the count, duration, usage etc
      groupedCategoryByYearMonth.forEach((dataCategoryList, key, map) => {
        // eg DOMESTIC-DATA-2018-AUG
        let call = 0
        let duration = 0 // minutes
        let usage = 0 // mb
        let excess = 0
        let sms = 0
        // console.log('groupedCategoryByYearMonth key is ' + key)
        dataCategoryList.forEach((dataCategory) => {
          // eg AUG-2018-DOMESTIC-DATA-CALLS-94 AUG-2018-DOMESTIC-DATA-MINUTES-OF-USE-94
          let dataType = dataCategory.categoryType // categoryType CALLS, MINUTES OF USE, USAGE
          let colIndex = dataCategory.colIndex
          let colValue = serviceLine[colIndex]
          if (dataType === 'CALLS') {
            call = colValue
          }
          if (dataType === 'MINUTES OF USE') {
            duration = colValue
          }
          if (dataType === 'USAGE (MB)') {
            usage = colValue
          }
          if (dataType === 'EXCESS USAGE ($)') {
            excess = colValue
          }
          if (dataType === 'SMS') {
            // console.log('SMS dataType is ' + dataType)
            sms = colValue
          }
          // use the case of usage, duration, call, excess etc
          // console.log(callMonth);
        })
        const groupMatch = key.match(groupPattern)
        let category = groupMatch[2].split('-').join(' ') // DOMESTIC-DATA to DOMESTIC DATA or OVERSEAS DATA or DOMESTIC
        let categoryYear = groupMatch[3] // eg 2018
        let monthLabel = groupMatch[5] // eg FEB
        //
        let yearMonth = Utils.convertToYearMonth(categoryYear, monthLabel)
        let monthUsage = {
          call: call, // number of calls made
          duration: duration, // duration of call in minutes
          usage: usage, // data usage in Mb
          excess: excess, // value $ of calls
          sms: sms
        }
        if (callCategoryUsageMap.has(category)) {
          // category would be DOMESTIC DATA, DOMESTIC VOICE, OVERSEAS DATA, DOMESTIC - DOMESTIC is SMS
          let snMonthCallMap = callCategoryUsageMap.get(category)
          snMonthCallMap.set(yearMonth, monthUsage) // add the month usage to the month map
        } else {
          let snMonthCallMap = new Map() // store the individual
          snMonthCallMap.set(yearMonth, monthUsage)
          callCategoryUsageMap.set(category, snMonthCallMap)
        }
      })

      // now callCatgoryMap should contain list of category eg DOMESTIC DATA, DOMESTIC VOICE and within it a map of all months and its usage
      callCategoryUsageMap.forEach((categoryMonthUsageList, category, map) => {
        // eg DOMESTIC-DATA-2018-AUG
        // create a generic category month object ie
        // sn : 1232232, category : domestic data 2018jan: 2232, 2018Feb: 12223
        let snMonthlyDataUsage = {
          _id: category.split(' ').join('-') + '-USAGE-' + sn,
          sn: sn,
          acct: accountNo,
          userName: userName,
          dataType: 'USAGE',
          category: category,
          avg3Months: 0.0,
          flexiPlanName: flexPlanName,
          shareablePlanName: shareablePlanName,
          nonShareablePlanName: nonShareablePlanName,
          totalDataAllowance: totalDataAllowance,
          bonusDataAllowance: bonusDataAllowance
        }
        let snMonthlyDataCall = {
          _id: category.split(' ').join('-') + '-CALL-' + sn,
          sn: sn,
          acct: accountNo,
          userName: userName,
          dataType: 'CALL',
          category: category
        }
        let snMonthlyDataDuration = {
          _id: category.split(' ').join('-') + '-DURATION-' + sn,
          sn: sn,
          acct: accountNo,
          userName: userName,
          dataType: 'DURATION',
          category: category,
          avg3Months: 0.0
        }
        let snMonthlyDataExcess = {
          _id: category.split(' ').join('-') + '-EXCESS-' + sn,
          sn: sn,
          acct: accountNo,
          userName: userName,
          dataType: 'EXCESS',
          category: category
        }
        let snMonthlyDataSMS = {
          _id: category.split(' ').join('-') + '-SMS-' + sn,
          sn: sn,
          acct: accountNo,
          userName: userName,
          dataType: 'SMS',
          category: category
        }
        // iterate through each month to get the monthly total
        categoryMonthUsageList.forEach((monthUsageList, yearMonth, map) => {
          if (category === DOMESTIC_DATA || category === DOMESTIC_VOICE || category === OVERSEAS_DATA) {
            snMonthlyDataUsage[yearMonth] = monthUsageList.usage
            snMonthlyDataCall[yearMonth] = monthUsageList.call
            snMonthlyDataDuration[yearMonth] = monthUsageList.duration
            snMonthlyDataExcess[yearMonth] = monthUsageList.excess

            // update latestActive..
            // console.log('categoryMonthUsageList for each yearMonth ' + yearMonth + ' latest yearMonth ' + latestYearMonth)
            if (yearMonth === latestYearMonth) {
              if (monthUsageList.usage && monthUsageList.usage > 0) {
                latestActive = true
              }
              if (monthUsageList.call && monthUsageList.call > 0) {
                latestActive = true
              }
              // if (monthUsageList.duration && monthUsageList.duration > 0) {
              //  latestActive = true
              // }
              // if (monthUsageList.excess && monthUsageList.excess > 0) {
              //  latestActive = true
              // }
              // console.log(' latestActive is ' + latestActive)
            }

            //
            // accumulates 12 months total
            if (category === DOMESTIC_DATA) {
              if (monthUsageList.usage > 0) {
                domesticDataUsage12MonthsCount = domesticDataUsage12MonthsCount + 1
                domesticDataUsage12MonthsTotal = domesticDataUsage12MonthsTotal + monthUsageList.usage
              }
              if (monthUsageList.call > 0) {
                domesticDataCall12MonthsCount = domesticDataCall12MonthsCount + 1
                domesticDataCall12MonthsTotal = domesticDataCall12MonthsTotal + monthUsageList.call
              }
            }
          }
          if (category === DOMESTIC_DATA) {
            snMonthlyDataUsage.avg3Months = avg3MonthsDomesticDataUsage // this goes to domestic data usage not duration
          }
          if (category === DOMESTIC_VOICE) {
            snMonthlyDataDuration.avg3Months = avg3MonthsDomesticVoiceDuration
          }
          if (category === 'DOMESTIC') {
            // SMS
            snMonthlyDataSMS[yearMonth] = monthUsageList.sms
            if (yearMonth === latestYearMonth) {
              if (monthUsageList.sms && monthUsageList.sms > 0) {
                latestActive = true
              }
            }
          }
        }) // for each call year month
        // this is an insert for each category
        snMonthCategoryUsageList.push(snMonthlyDataUsage)
        snMonthCategoryUsageList.push(snMonthlyDataCall)
        snMonthCategoryUsageList.push(snMonthlyDataDuration)
        snMonthCategoryUsageList.push(snMonthlyDataExcess)
        snMonthCategoryUsageList.push(snMonthlyDataSMS)
        // populate equipment summary
        if (snMonthlyDataCall[latestYearMonth]) {
          latestDomesticDataCall = snMonthlyDataCall[latestYearMonth]
          // console.log('data call for ' + latestYearMonth + ' is ' + snMonthlyDataCall[latestYearMonth])
        }
        if (snMonthlyDataUsage[latestYearMonth]) {
          latestDomesticDataUsage = snMonthlyDataUsage[latestYearMonth]
          // console.log('data usage for ' + latestYearMonth + ' is ' + snMonthlyDataUsage[latestYearMonth])
        }
      }) // for each call category

      // create the service number...
      let serviceNumber = {
        _id: sn,
        sn: sn,
        acct: accountNo,
        latestActive: latestActive,
        latestBillTotal: latestBillTotal,
        cidn: serviceLine[headerColumns.get('CIDN'.toUpperCase())],
        userName: serviceLine[headerColumns.get('USER NAME'.toUpperCase())],
        sim: serviceLine[headerColumns.get('SIM Number'.toUpperCase())],
        simSerial: serviceLine[headerColumns.get('SIM Serial Number'.toUpperCase())],
        simNetwork: serviceLine[headerColumns.get('SIM Network'.toUpperCase())],
        snActivationDate: serviceLine[headerColumns.get('Service Activation Date'.toUpperCase())],
        snEndDate: serviceLine[headerColumns.get('Service End Date'.toUpperCase())],
        avg3MonthsDomesticDataUsage: avg3MonthsDomesticDataUsage,
        avg3MonthsDomesticVoiceDuration: avg3MonthsDomesticVoiceDuration,
        avg3MonthsBill: avg3MonthsBill,
        snStatus: serviceLine[headerColumns.get('Service Status'.toUpperCase())],
        snStartDate: serviceLine[headerColumns.get('Service Start Date'.toUpperCase())],
        flexiPlan: serviceLine[headerColumns.get('Flexiplan'.toUpperCase())],
        flexiPlanName: flexPlanName,
        flexiPlanType: flexiPlanType,
        flexPlanFee: flexPlanFee,
        flexiShareableFlag: flexiShareableFlag,
        flexiStart: serviceLine[headerColumns.get('Flexiplan Start Date'.toUpperCase())],
        flexiEnd: serviceLine[headerColumns.get('Flexiplan End Date'.toUpperCase())],
        imei: serviceLine[headerColumns.get('IMEI'.toUpperCase())],
        make: serviceLine[headerColumns.get('Make'.toUpperCase())],
        model: serviceLine[headerColumns.get('Model'.toUpperCase())],
        os: serviceLine[headerColumns.get('Operating System'.toUpperCase())],
        deviceName: serviceLine[headerColumns.get('Device Name'.toUpperCase())],
        deviceType: serviceLine[headerColumns.get('Device Type'.toUpperCase())],
        deviceCategory: serviceLine[headerColumns.get('Device Category'.toUpperCase())],
        deviceCapability: serviceLine[headerColumns.get('Device Capability'.toUpperCase())],
        dataPlan1: serviceLine[headerColumns.get('Data Plan 1'.toUpperCase())],
        dataPlan1Description: serviceLine[headerColumns.get('Data Plan 1 Description'.toUpperCase())],
        dataPlan1MonthlyCommitment: serviceLine[headerColumns.get('Data Plan 1 Monthly Commitment'.toUpperCase())],
        dataPlan2: serviceLine[headerColumns.get('Data Plan 2'.toUpperCase())],
        dataPlan2Description: serviceLine[headerColumns.get('Data Plan 2 Description'.toUpperCase())],
        dataPlan2MonthlyCommitment: serviceLine[headerColumns.get('Data Plan 2 Monthly Commitment'.toUpperCase())],
        shareableDataPlan: shareableDataPlan,
        shareablePlanName: shareablePlanName,
        shareablePlanFee: shareablePlanFee,
        shareableDataPlan2: shareableDataPlan2,
        shareablePlanName2: shareablePlanName2,
        shareablePlanFee2: shareablePlanFee2,
        nonShareableDataPlan1: nonShareableDataPlan1,
        nonShareablePlanName1: nonShareablePlanName1,
        nonShareablePlanFee1: nonShareablePlanFee1,
        nonShareableDataPlan2: nonShareableDataPlan2,
        nonShareablePlanName2: nonShareablePlanName2,
        nonShareablePlanFee2: nonShareablePlanFee2,
        shareableBonusDataPlan1: shareableBonusDataPlan1,
        shareableBonusPlanName1: shareableBonusPlanName1,
        shareableBonusPlanFee1: shareableBonusPlanFee1,
        shareableBonusDataPlan2: shareableBonusDataPlan2,
        shareableBonusPlanName2: shareableBonusPlanName2,
        shareableBonusPlanFee2: shareableBonusPlanFee2,
        nonShareableBonusDataPlan1: nonShareableBonusDataPlan1,
        nonShareableBonusPlanName1: nonShareableBonusPlanName1,
        nonShareableBonusPlanFee1: nonShareableBonusPlanFee1,
        nonShareableBonusDataPlan2: nonShareableBonusDataPlan2,
        nonShareableBonusPlanName2: nonShareableBonusPlanName2,
        nonShareableBonusPlanFee2: nonShareableBonusPlanFee2,
        serviceContract1StartDate: serviceLine[headerColumns.get('Service Contract 1 Start Date'.toUpperCase())],
        serviceContract1EndDate: serviceLine[headerColumns.get('Service Contract 1 End Date'.toUpperCase())],
        serviceContract2StartDate: serviceLine[headerColumns.get('Service Contract 2 Start Date'.toUpperCase())],
        serviceContract2EndDate: serviceLine[headerColumns.get('Service Contract 2 End Date'.toUpperCase())],
        puk: serviceLine[headerColumns.get('PUK'.toUpperCase())],
        mro1Contract: serviceLine[headerColumns.get('MRO 1 Contract'.toUpperCase())],
        mro1ContractStartDate: serviceLine[headerColumns.get('MRO 1 Contract Start Date'.toUpperCase())],
        mro1ContractEndDate: serviceLine[headerColumns.get('MRO 1 Contract End Date'.toUpperCase())],
        mro1ContractEtc: serviceLine[headerColumns.get('MRO 1 Contract ETC'.toUpperCase())],
        mro1ContractMonthlyCommitment: serviceLine[headerColumns.get('MRO 1 Monthly Commitment'.toUpperCase())],
        totalDataAllowance: totalDataAllowance,
        bonusDataAllowance: bonusDataAllowance,
        // configure options section
        internationalRoaming: serviceLine[headerColumns.get('International Roaming Active'.toUpperCase())], // value to be either YES or NO
        spendLimitActive: serviceLine[headerColumns.get('Spend Limit Active'.toUpperCase())], // value to be either YES or NO
        mmsAccess: serviceLine[headerColumns.get('MMS Access'.toUpperCase())], // value to be either YES or NO
        videoCallingAccess: serviceLine[headerColumns.get('Video Calling Access'.toUpperCase())], // value to be either YES or NO
        barOutgoingVoice: serviceLine[headerColumns.get('Bar Outgoing Voice'.toUpperCase())], // value to be either YES or NO
        barOutgoingSMS: serviceLine[headerColumns.get('Bar Outgoing SMS'.toUpperCase())], // value to be either YES or NO
        barIncomingSMS: serviceLine[headerColumns.get('Bar Incoming SMS'.toUpperCase())], // value to be either YES or NO
        barPremiumSSMS: serviceLine[headerColumns.get('Bar Premium SMS'.toUpperCase())], // value to be either YES or NO
        telstraCorp: serviceLine[headerColumns.get('Telstra Corp'.toUpperCase())], // value to be either YES or NO
        telstraXtranet: serviceLine[headerColumns.get('Telstra Xtranet'.toUpperCase())], // value to be either YES or NO
        telstraDataPack: serviceLine[headerColumns.get('Telstra Data Pack'.toUpperCase())], // value to be either YES or NO
        telstraPCPack: serviceLine[headerColumns.get('Telstra PC Pack'.toUpperCase())], // value to be either YES or NO
        telstraInternet: serviceLine[headerColumns.get('Telstra Internet'.toUpperCase())], // value to be either YES or NO
        telstraWap: serviceLine[headerColumns.get('Telstra WAP'.toUpperCase())], // value to be either YES or NO
        iphoneAPN: serviceLine[headerColumns.get('iPhone APN'.toUpperCase())], // value to be either YES or NO
        barNextGWAP: serviceLine[headerColumns.get('Bar NextG WAP'.toUpperCase())],
        barNextGMMS: serviceLine[headerColumns.get('Bar NextG MMS'.toUpperCase())],
        barNextGInternetAccess: serviceLine[headerColumns.get('Bar NextG Internet Access'.toUpperCase())],
        barVideoCalls: serviceLine[headerColumns.get('Bar Video Calls'.toUpperCase())],
        telstraWapGsm: serviceLine[headerColumns.get('Telstra WAP GSM'.toUpperCase())], // value to be either YES or NO
        mmsAccessGsm: serviceLine[headerColumns.get('MMS Access GSM'.toUpperCase())], // value to be either YES or NO
        telstraInternetGSM: serviceLine[headerColumns.get('Telstra Internet GSM'.toUpperCase())], // value to be either YES or NO
        barGsmWap: serviceLine[headerColumns.get('Bar GSM WAP'.toUpperCase())], // value to be either YES or NO
        bar2gMms: serviceLine[headerColumns.get('Bar 2G MMS'.toUpperCase())], // value to be either YES or NO
        barGsmMobileInternet: serviceLine[headerColumns.get('Bar GSM Mobile Internet'.toUpperCase())], // value to be either YES or NO
        liberateFlag: serviceLine[headerColumns.get('Liberate Flag'.toUpperCase())], // value to be either YES or NO
        oneNumberFlag: serviceLine[headerColumns.get('One Number Flag'.toUpperCase())], // value to be either YES or NO
        barRoaming: serviceLine[headerColumns.get('Bar Roaming'.toUpperCase())], // value to be either YES or NO
        barIDD: serviceLine[headerColumns.get('Bar IDD'.toUpperCase())], // value to be either YES or NO
        usernamePrintOnBill: serviceLine[headerColumns.get('Username Print on Bill'.toUpperCase())], // value to be either Y or N
        networkFeatureCode: serviceLine[headerColumns.get('Network Feature Code'.toUpperCase())],
        messagebankCat: serviceLine[headerColumns.get('Messagebank Cat'.toUpperCase())],
        noUsageSixMonths: serviceLine[headerColumns.get('No Usage In Last 6 Months'.toUpperCase())],
        mroContractDeviceName: mroContractDeviceName,
        mroContractStartDate: mroContractStartDate,
        mroContractEndDate: mroContractEndDate,
        mroMonthlyCommitment: mroMonthlyCommitment,
        mroContractETC: mroContractETC,
        tradeInValue: serviceLine[headerColumns.get('Device Trade in Indicative Value inc GST'.toLocaleUpperCase())]
      }

      serviceNumbers.push(serviceNumber)

      //
      // deviceName is the lowest denomination for equipment summary table then
      // make and finally
      // deviceType as the highest aggregator
      //
      // const planKey = (flexPlanName || 'NO PLAN') + '-' + (shareablePlanName || 'NO PLAN')
      const equipmentKey = serviceNumber.deviceType + '-' + serviceNumber.make + '-' + serviceNumber.deviceName // + '-' + planKey
      if (equipmentSummaryMap.get(equipmentKey)) {
        // key exists
        // console.log('equipmentSummary exists ' + equipmentKey)
        let equipmentSummary = equipmentSummaryMap.get(equipmentKey)
        // console.log('in the equipment summary map exists loop calling extractEquipmentSummary for equipment for sn ' + serviceNumber.sn + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
        extractEquipmentSummary(
          serviceNumber,
          equipmentSummary,
          latestBillTotal,
          latestDomesticDataCall,
          latestDomesticDataUsage,
          domesticDataUsage12MonthsCount,
          domesticDataUsage12MonthsTotal,
          domesticDataCall12MonthsCount,
          domesticDataCall12MonthsTotal,
          bill12MonthsCount,
          bill12MonthsTotal,
          flexPlanName,
          flexiPlanType,
          flexiShareableFlag,
          shareablePlanName,
          shareablePlanName2,
          shareableBonusPlanName1,
          shareableBonusPlanName2,
          nonShareablePlanName1,
          nonShareablePlanName2,
          nonShareableBonusDataPlan1,
          nonShareableBonusDataPlan2,
        )
        let accountSummary = equipmentSummary.acctSummaryMap.get(accountNo)
        // console.log('in the equipment summary accountSummary for ' + accountNo + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType + ' is ', accountSummary)
        if (accountSummary) {
          // console.log('in the equipment summary map calling extractEquipmentSummary ACCOUNT for sn ' + serviceNumber.sn + ' account number ' + accountNo + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType)
          extractEquipmentSummary(
            serviceNumber,
            accountSummary,
            latestBillTotal,
            latestDomesticDataCall,
            latestDomesticDataUsage,
            domesticDataUsage12MonthsCount,
            domesticDataUsage12MonthsTotal,
            domesticDataCall12MonthsCount,
            domesticDataCall12MonthsTotal,
            bill12MonthsCount,
            bill12MonthsTotal,
            flexPlanName,
            flexiPlanType,
            flexiShareableFlag,
            shareablePlanName,
            shareablePlanName2,
            shareableBonusPlanName1,
            shareableBonusPlanName2,
            nonShareablePlanName1,
            nonShareablePlanName2,
            nonShareableBonusDataPlan1,
            nonShareableBonusDataPlan2,
          )
        } else {
          // console.log('in the equipment summary map exists loop calling createEquipmentSummary for sn ' + serviceNumber.sn + ' account number ' + accountNo)
          //accountSummary = createEquipmentSummary(serviceNumber, latestBillTotal, latestDomesticDataCall, latestDomesticDataUsage, domesticDataUsage12MonthsCount, domesticDataUsage12MonthsTotal, domesticDataCall12MonthsCount, domesticDataCall12MonthsTotal, bill12MonthsCount, bill12MonthsTotal, flexPlanName, shareablePlanName)
          accountSummary = createEquipmentSummary(
            serviceNumber,
            latestBillTotal,
            latestDomesticDataCall,
            latestDomesticDataUsage,
            domesticDataUsage12MonthsCount,
            domesticDataUsage12MonthsTotal,
            domesticDataCall12MonthsCount,
            domesticDataCall12MonthsTotal,
            bill12MonthsCount,
            bill12MonthsTotal,
            flexPlanName,
            flexiPlanType,
            flexiShareableFlag,
            shareablePlanName,
            shareablePlanName2,
            shareableBonusPlanName1,
            shareableBonusPlanName2,
            nonShareablePlanName1,
            nonShareablePlanName2,
            nonShareableBonusDataPlan1,
            nonShareableBonusDataPlan2,
            mroContractDeviceName,
          )
          // accountSummary.qty = 0 // this is done so it does not double count when calling extractedEquipmentSummary see below line 638
          equipmentSummary.acctSummaryMap.set(accountNo, accountSummary)
          equipmentSummary.acctNumbers.push(accountNo)
        }
        // console.log('ENDED accountSummary for ' + accountNo + ' equipment ' + serviceNumber.deviceName + ' type ' + serviceNumber.deviceType + ' is ', accountSummary + ' ******* ')
        // console.log('accumulating ' + equipmentKey + ' 12 month bill count ' + equipmentSummary.bill12MonthsCount)
        // not sure if we need to set the updated record back to the map...
      } else {
        // key does not exist
        // console.log('in the equipment summary map does NOT exists loop calling createEquipmentSummary for sn ' + serviceNumber.sn + ' account number ' + accountNo)
        const equipmentSummaryRaw = createEquipmentSummary(
          serviceNumber,
          latestBillTotal,
          latestDomesticDataCall,
          latestDomesticDataUsage,
          domesticDataUsage12MonthsCount,
          domesticDataUsage12MonthsTotal,
          domesticDataCall12MonthsCount,
          domesticDataCall12MonthsTotal,
          bill12MonthsCount,
          bill12MonthsTotal,
          flexPlanName,
          flexiPlanType,
          flexiShareableFlag,
          shareablePlanName,
          shareablePlanName2,
          shareableBonusPlanName1,
          shareableBonusPlanName2,
          nonShareablePlanName1,
          nonShareablePlanName2,
          nonShareableBonusDataPlan1,
          nonShareableBonusDataPlan2,
          mroContractDeviceName,
        )
        const equipmentSummary = { ...equipmentSummaryRaw, ...{ acctNumbers: [], acctSummaryMap: new Map() } }
        // TODO group equipment summary by account rough cut need to better calculate equipment summary
        const accountSummary = createEquipmentSummary(
          serviceNumber,
          latestBillTotal,
          latestDomesticDataCall,
          latestDomesticDataUsage,
          domesticDataUsage12MonthsCount,
          domesticDataUsage12MonthsTotal,
          domesticDataCall12MonthsCount,
          domesticDataCall12MonthsTotal,
          bill12MonthsCount,
          bill12MonthsTotal,
          flexPlanName,
          flexiPlanType,
          flexiShareableFlag,
          shareablePlanName,
          shareablePlanName2,
          shareableBonusPlanName1,
          shareableBonusPlanName2,
          nonShareablePlanName1,
          nonShareablePlanName2,
          nonShareableBonusDataPlan1,
          nonShareableBonusDataPlan2,
        )
        equipmentSummary.acctSummaryMap.set(accountNo, accountSummary)
        equipmentSummary.acctNumbers.push(accountNo)
        equipmentSummaryMap.set(equipmentKey, equipmentSummary)
      }

      // create deviceInContract if mroContractDeviceName is not empty
      if (mroContractDeviceName && mroContractDeviceName !== '') {
        const deviceInContract = createDeviceInContract(
          serviceNumber,
          mroContractDeviceName,
          mroContractStartDate,
          mroContractEndDate,
          mroMonthlyCommitment,
          mroContractETC,
        )
        deviceInContractMap.set(serviceNumber.sn, deviceInContract)
      }
    }
  } // end of for loop

  // console.log('equipmentSummaryMap is ')
  const equipmentSummaryList = [...equipmentSummaryMap.values()]

  //Convert buybackDb items to map
  const buybackDb = getBuybackDb()
  const buybackMap = await buybackDb.allDocs({ include_docs: true }).then((items) => {
    return items.rows.reduce((map, item) => {
      map[item.doc.modelCode] = item.doc
      return map
    }, {})
  })

  //add model price to service numbers
  const serviceNumbersWithBuybackPrice = serviceNumbers.map((item) => {
    const modelName = item.model
    const priceInfo = buybackMap[modelName] || { price: 0 } // Default value to 0 if model not found
    return { ...item, modelPrice: priceInfo.price }
  })

  //add model price to equipmentSummary
  const equipmentSummaryListWithBuybackPrice = equipmentSummaryList.map((item) => {
    const modelName = item.model
    const qty = item.qty
    const priceInfo = buybackMap[modelName] || { price: 0 } // Default value to 0 if model not found
    return { ...item, modelPrice: priceInfo.price * qty }
  })

  const deviceInContractList = [...deviceInContractMap.values()]

  return Promise.resolve([
    serviceNumbersWithBuybackPrice,
    snMonthCategoryUsageList,
    equipmentSummaryListWithBuybackPrice,
    customerName,
    billingName,
    businessUnit,
    bill12MonthsGrandTotal,
    biltzFileVersion,
    deviceInContractList,
  ])
}

/**
 * returns a map of key value pair when the value is a list of objects
 * @param list
 * @param keyGetter
 * @returns {Map<any, any>}
 */
function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

async function calculateCurrentMonthTotal(currentMonth) {
  console.log('calculateCurrentMonthTotal')
  // total of column 'Bill Total Aug 2018'
}

/**
 *
 * @param {*} rows
 * @returns {Map<string, {price:number}>}
 */
async function buildBuybackPrice(rows) {
  let rowNumber = 1 // row 0 is the header hence setting i to 1
  const iMax = rows.length
  const headerRow = rows[0]
  const priceList = []

  let headerValue = new Map()
  for (let i = 0; i < headerRow.length; i++) {
    headerValue.set(headerRow[i].toUpperCase(), i)
  }

  for (; rowNumber < iMax; rowNumber++) {
    let models = rows[rowNumber]
    const modelCode = models[headerValue.get('Model Code'.toUpperCase())]
    const price = models[headerValue.get('Price'.toUpperCase())]
    priceList.push({ modelCode, price })
  }
  return priceList
}

export { loadData, loadBuybackData, resetGlobalAccountFilter }
export { calculateCurrentMonthTotal }
